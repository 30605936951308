// Basic information component 
const BasicIntro = () => {
	return (
		<>
		<h2>I am Junaid</h2>
	    <h2>I live in Bengaluru</h2>
	    </> 
    )
}

export default BasicIntro;